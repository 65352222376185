<template>
  <b-container>
    <b-card>
      <b-card-title class="text-center mb-5" :title="$t('ticketGroups.link')"></b-card-title>
      <GoldflamTable
        :new-item="newTicketGroupObject"
        :all-items="allTicketGroups"
        :table-fields="tableFields"
        :edit-function="editTicketGroupFunction"
        :delete-function="deleteTicketGroup"
        :delete-modal-prevent-close="true"
        :busy="datatableBusy"
      >
      </GoldflamTable>
      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('ticketGroups.edit-ticket-group')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveTicketGroup(editTicketGroup)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editTicketGroupName"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('ticketGroups.name')"
          >
            <b-form-input
              id="editTicketGroupName"
              type="text"
              v-model="$v.editTicketGroup.name.$model"
              :state="validateFormInput('name')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editTicketNames"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('ticketGroups.ticket-names')"
            :description="$t('ticketGroups.ticket-names-description')"
          >
            <b-form-textarea
              id="editTicketNames"
              v-model="$v.editTicketGroup.ticketNames.$model"
              :state="validateFormInput('ticketNames')"
              no-resize
              rows="6"
              max-rows="6"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep as _cloneDeep } from 'lodash'

export default {
  name: 'ManageTicketGroups',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { GoldflamTable },

  data() {
    return {
      objectModels: {
        ticketGroup: {
          id: null,
          name: '',
          ticketNames: ''
        }
      },
      editTicketGroup: {},
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      allTicketGroups: [],
      tableFields: [
        { key: 'name', label: this.$t('general.ticketId'), sortable: true },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '100px' }
      ],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      datatableBusy: false,
      showDeleteWarning: false,
      deleteModalWarningMessage: null
    }
  },
  validations: {
    editTicketGroup: {
      name: {
        required
      },
      ticketNames: {
        required
      }
    }
  },
  created() {
    this.editTicketGroup = _cloneDeep(this.objectModels.ticketGroup)
  },
  mounted() {
    this.datatableBusy = true
    this.loadAllTicketGroups().finally(() => {
      this.datatableBusy = false
    })
  },
  computed: {
    newTicketGroupObject() {
      return _cloneDeep(this.objectModels.ticketGroup)
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editTicketGroup[prop]
      return $dirty ? !$invalid : null
    },
    loadAllTicketGroups() {
      let self = this
      return this.getRequest(
        '/ticket_groups',
        new RequestConfig().onSuccess(res => {
          self.allTicketGroups = res.data
        })
      )
    },
    saveTicketGroup(ticketGroup) {
      ticketGroup.ticketNames = ticketGroup.ticketNames
        .split(/\r?\n/)
        .filter(line => line.trim() !== '')
        .join('\n')
      let url = '/ticket_groups'
      if (ticketGroup.id) {
        url += `/${ticketGroup.id}`
      }
      let self = this
      this.postRequest(
        url,
        ticketGroup,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(self.$t('ticketGroups.success-msg'))
            self.loadAllTicketGroups()
          })
          .onError(err => {
            self.displayError(err.response.data.message)
          })
      )
    },
    deleteTicketGroup(ticketGroup, modalId) {
      let self = this
      const id = ticketGroup.id
      this.deleteRequest(
        `/ticket_groups/${id}`,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(this.$t('ticketGroups.delete-success-msg'))
            self.loadAllTicketGroups()
            self.hideDeleteModalManually(modalId)
          })
          .onError(err => {
            self.displayError(err.response.data.message)
            self.hideDeleteModalManually(modalId)
          })
      )
    },
    hideDeleteModalManually(modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    editTicketGroupFunction(ticketGroup) {
      this.editTicketGroup = _cloneDeep(ticketGroup)
      this.modalControl.edit.show = true
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editTicket = _cloneDeep(this.objectModels.ticketGroup)
      this.$v.$reset()
    }
  }
}
</script>

<style scoped></style>
